<template>
  <div class="notification-settings">
    <div class="notification-settings__title">Notifications</div>
    <div class="notification-settings__list">

      <div class="notification-settings__item" v-for="setting in notificationSettingsList" :key="setting.id">
        <FveCheckbox
          :field="{
            name: setting.key,
            model: true,
          }"
          @update:modelValue="changeFilterState(setting, $event)"
          :label="setting.name"
          :key="setting.key"
        />
<!--        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z"/></svg>-->
<!--        <span class="notification-settings__item-title">{{ setting.name }}</span>-->
      </div>
    </div>
    <div class="notification-settings__title">Meeting time</div>
    <div class="notification-settings__list">
      <div class="notification-settings__item" v-for="setting in meetingSettingsList" :key="setting.id">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z"/></svg>
        <span class="notification-settings__item-title">{{ setting.name }}</span>
      </div>
    </div>

  </div>
</template>

<script>

import FveCheckbox from '@fve/Switch/FveCheckbox';
import {notificationFilterState} from "@store/notification.store";

export default {
  name: 'NotificationSettings',
  components: {
    FveCheckbox
  },
  data() {
    return {
      notificationSettingsList: [
        {id: 1, name: 'Email',    key: 'email',    value: 'EMAIL',              },
        {id: 2, name: 'Chat',     key: 'chat',     value: 'CHAT',               },
        {id: 3, name: 'Board',    key: 'board',    value: 'BOARD',              },
        {id: 4, name: 'Reminder', key: 'reminder', value: 'SCHEDULE::REMINDER', },
        {id: 5, name: 'Task',     key: 'task',     value: 'SCHEDULE::TASK',     },
        {id: 6, name: 'Meeting',  key: 'meeting',  value: 'SCHEDULE::MEETING',  },
      ],
      meetingSettingsList: [
        {id: 1, name: 'Local time'},
        {id: 2, name: 'Organizer time'},
      ]
    };
  },
  methods: {
    changeFilterState({ key, value }, $event) {
      if (notificationFilterState.value[key] && !$event) {
        delete notificationFilterState.value[key];
      } else {
        notificationFilterState.value[key] = value;
      }

      console.log(notificationFilterState.value);
    },
  }
};
</script>

<style lang="scss" scoped>

.notification-settings {
  padding: 40px;
  width: 100%;

  .notification-settings__title {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .notification-settings__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .notification-settings__item {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    color: var(--color-gray-07);

    &:last-of-type {
      margin-bottom: 0;
    }

    & > svg {
      fill: var(--color-gray-04);
    }

  }

  .notification-settings__item-title {
    display: inline-block;
    margin-left: 8px;
    color: var(--color-gray-07);
    font-size: 0.875rem;
    line-height: 1.45;
  }

}

</style>