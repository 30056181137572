<template>
  <div class="profile-notification">
      <div class="profile-notification__header">
        <div class="profile-notification__title">Tasks & notification</div>
        <span class="profile-notification__settings-link" @click="openNotificationSettings">Settings</span>
      </div>

      <div class="ps-wrapper">
        <perfect-scrollbar>
          <template v-for="date in notifyGroupList.notificationDate" :key="date">
            <NotificationGroup :date="date" :notifications="notifyGroupList.notificationObj[date]"/>
          </template>
          
        </perfect-scrollbar>
      </div>
    </div>
</template>

<script>

import NotificationGroup from '@component/Notification/NotificationGroup';
import NotificationSettings from '@component/Notification/NotificationSettings';
import { notificationList } from '@store/test/testNotification';
import {notificationFilterState, prepareNotificationData} from "@store/notification.store";

export default {
  name: 'UserProfileNotification',
  components: {
    NotificationGroup,
  },
  methods: {
    openNotificationSettings() {
      this.$dialog.open(
          NotificationSettings,
          {},
          {
            group: 'modal', // не обязательно
            theme: 'notification-settings'
          }
      );
    }
  },
  data() {
    return {
    }
  },
  computed: {
    notifyGroupList() {
      let filteredList = notificationList.value;
      if (Object.keys(notificationFilterState.value).length) {
        filteredList = filteredList.filter(item => {
          for (let key in notificationFilterState.value) {
            if (item.type.includes(notificationFilterState.value[key])) {
              return item;
            }
          }
        });
      }
      
      console.log(filteredList);
      
      return prepareNotificationData(filteredList);
    }
  },
};
</script>

<style lang="scss" scoped>

.ps-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden
}

.profile-notification {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: var(--sidebar-notification-background);
  height: 100%;
  border: 1px solid var(--common-border-color);

  .profile-notification__header {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 32px;
    line-height: 1.45;
    background-color: var(--color-gray-01);
  }

  .profile-notification__title {
    font-size: 1.375rem;
    font-weight: 700;
    color: var(--color-gray-08);
  }

  .profile-notification__settings-link {
    font-size: 0.875rem;
    color: var(--color-gray-05);
    cursor: pointer;
  }

  .profile-notification__body {
    display: flex;
    flex-direction: column;
  }

}

</style>
