<template>
  <div class="user-panel">
    
    <router-link :to="{ name: $routeName.USER_PROFILE_EDIT }" class="user-panel__edit-link">
      <Avatar
          class="user-panel__avatar"
          :name="fullName"
          :image="profilePhoto"
      />
      <div class="user-panel__username">{{ fullName }}</div>
      <div class="user-panel__notify-click">Click to edit</div>
    </router-link>
    
    <div class="user-panel__bar">
      <span class="user-panel__label">Email</span>
      <p class="user-panel__content">{{ user.mail }}</p>
    </div>

    <div class="user-panel__bar" v-if="user.companyName">
      <span class="user-panel__label">Company</span>
      <p class="user-panel__content">{{ user.companyName }}</p>
    </div>
  
    <div class="user-panel__bar" v-if="user.birthday">
      <span class="user-panel__label">Birthdate</span>
      <p class="user-panel__content">{{ birthDate }} <span class="user-panel__age">{{ yearsOld }}</span></p>
    </div>

    <div class="user-panel__bar" v-if="user.gender">
      <span class="user-panel__label">Gender</span>
      <p class="user-panel__content">{{ user.gender }}</p>
    </div>

    <div class="user-panel__bar" v-if="user.hobbiesAndInterests">
      <span class="user-panel__label">Hobbies and Interests</span>
      <p class="user-panel__content">{{ user.hobbiesAndInterests }}</p>
    </div>

    <div class="user-panel__bar" v-if="user.countryOfResidence">
      <span class="user-panel__label">Country of Residence</span>
      <p class="user-panel__content">{{ user.countryOfResidence }}</p>
    </div>
  
    <!--    <div class="user-panel__bar">-->
    <!--      <span class="user-panel__label">Family Status</span>-->
    <!--      <p class="user-panel__content">Non-marriage</p>-->
    <!--    </div>-->
  
    <div class="user-panel__actions">
      <button class="btn btn--outline" @click.prevent.stop="logout">Log out</button>
    </div>
    
    
  </div>
</template>

<script>

import Avatar from "@component/Avatar";


export default {
  name: "UserProfilePanel",
  components: {
    Avatar
  },
  data() {
    return {
      user: this.$user.Profile.getProfileObj()
    }
  },
  computed: {
    profilePhoto() {
      let image = null;
      if (this.user && this.user.profilePhoto) {
        image = this.user.profilePhoto;
      }
      return image;
    },
    fullName() {
      let name = "My Profile";
      if (this.user.firstName || this.user.lastName) {
        name = `${this.user.firstName} ${this.user.lastName}`;
      }
      return name;
    },
    
    birthDate() {
      return DateTime(this.user.birthday).format('DD/MM/YYYY');
    },
    
    yearsOld() {
      const currentDateNum = Number.parseInt(DateTime(Date()).format('YYYY'), 10);
      const birthDateNum = Number.parseInt(DateTime(this.user.birthday).format('YYYY'));
      let years = 0;
      if (currentDateNum > birthDateNum) {
        years = currentDateNum - birthDateNum;
      }
      return `${years} years old`;
    },
  },
  methods: {
    logout() {
      this.$user.logout();
      this.$router.push({ name: this.$routeName.USER_AUTH });
    }
  }
};
</script>

<style lang="scss" scoped>

.user-panel {
  background-color: var(--color-gray-01);
  padding: 40px;
  height: 100%;
  max-width: 431px;
  width: 100%;
  // border-left: 1px solid var(--common-border-color);
  border-top: 1px solid var(--common-border-color);
  display: flex;
  flex-direction: column;

  .user-panel__avatar {
    @include icon-size(6.25);
    margin: 0 auto 16px;
  }

  .user-panel__username {
    font-size: 1.375rem;
    line-height: 1.45;
    font-weight: 700;
    color: var(--color-gray-07);
    text-align: center;
  }
  
  .user-panel__edit-link {
    display: block;
  }

  .user-panel__notify-click,
  .user-panel__label {
    font-size: 0.875rem;
    line-height: 1.45;
    color: var(--color-gray-05);
  }

  .user-panel__notify-click {
    text-align: center;
    margin-bottom: 32px;
  }

  .user-panel__bar {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .user-panel__content {
    margin-top: 3px;
  }
  
  .user-panel__age {
    margin-left: 12px;
    color: var(--color-gray-05);
  }
  
  .user-panel__actions {
    display: flex;
    margin-top: auto;
    justify-content: center;
  }

}

</style>
